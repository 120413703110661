<!--

 Usage:
   Place inside a container that has relative or absolute class:

   <div class="relative">
     <yb-sql :sql="getSql" class="absolute right-2 top-2" />
   </div>

 Then define a method that either returns the SQL, or returns a Promise that returns SQL:<template>

 <s c r i p t>
 export default {
   methods: {
     getSql() {
       return 'SELECT * FROM sys.const';
     }

     /** OR **/
     async getSql() {
       return await someService.getSql(....);
     }
   }
 }
 </s c r i p t>

Props:
  tooltip: String What to show on the button when user hovers (eg. "Show SQL for this EXTERNAL STORAGE")

NB: the script tag above was obfuscated to fool silly dev-time tooling

-->
<template>
  <v-dropdown placement="left">
    <div v-tooltip="{content: tooltip, placement: 'left'}" class="bg-yb-gray-medium text-white rounded text-sm py-1 px-2 cursor-pointer" @click="clicked">
      SQL
    </div>
    <template #popper>
      <div class="inline-block rounded border border-yb-gray-lightest bg-white text-yb-gray-medium dark:bg-yb-black dark:text-yb-gray-faintest shadow-lg p-3 space-y-2 relative">
        <yb-close v-close-popper tiny />
        <yb-highlight
          v-if="!loading"
          language="sql"
          :content="loadedContent"
          class="!border-0"
          :class="effectiveClasses"
          :style="styles"
        />
        <div v-else class="flex flex-row items-center pt-2">
          <div class="h-6 w-6">
            <yb-processing class="w-full h-full" />
          </div>
          <span class="text-sm">Loading...</span>
        </div>

        <div v-if="!!loadedContent" class="w-full flex justify-end">
          <yb-button v-yb-clipboard:text="loadedContent" v-close-popper class="border border-yb-gray-faint justify-end" clipboard-label="(SQL)">
            <yb-icon class="yb-button-icon" icon="clipboard" />
            <translate>Copy to Clipboard</translate>
          </yb-button>
        </div>
      </div>
    </template>
  </v-dropdown>
</template>

<script>

export default {
  props: {
    sql: Function,
    tooltip: {
      type: String,
      default: 'Show/copy the SQL for this operation'
    },
    classes: {
      type: String,
      default: 'text-sm overflow-auto whitespace-pre-wrap my-4 mx-2'
    },
    heightClass: {
      type: String,
      default: 'max-h-20'
    },
    widthClass: {
      type: String,
      default: 'w-96'
    },
    styles: {
      type: Object,
      default() {
        return {
          lineHeight: '16px',
          minWidth: '120px'
        }
      }
    }
  },
  data() {
    return {
      content: null,
      loading: false
    }
  },
  computed: {
    effectiveClasses() {
      const result = []
      result.push(...this.classes.split(/ /g))
      result.push(this.heightClass)
      result.push(this.widthClass)
      result.push('whitespace-pre-wrap')
      return result
    },
    loadedContent() {
      if (!this.content) {
        this.loadContent()
      }
      return this.content
    }
  },
  methods: {
    async loadContent() {
      if (!this.loading) {
        this.loading = true
        try {
          this.content = await Promise.resolve(this.sql())
          if (!this.content) {
            this.content = '(not available)' // Avoid infinite recursion.
          }
        } catch (e) {
          console.log('Could not load sql content')
          console.error(e)
          this.content = `Error loading content: ${String(e)}`
        } finally {
          this.loading = false
        }
      }
    },
    clicked() {
      this.content = null
    }
  }
}
</script>
